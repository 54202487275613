import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'

const Category = ({ index, name, image }) => (
  <CategoryWrapper>
    <Background>
      <Title>{name}</Title>
      {index === 0 && <Overlay />}
      <Image
        fluid={image.imageFile.childImageSharp.fluid}
        alt={image.altText}
        style={{ position: 'absolute' }}
        backgroundColor="#121212"
      />
    </Background>
  </CategoryWrapper>
)

export default Category

const CategoryWrapper = styled.article`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const Title = styled.h2`
  position: absolute;
  bottom: 1rem;
  left: 1.4rem;
  color: #fff;
  font-family: ${props => props.theme.fonts.maison};
  font-weight: 400;
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 0.4rem;
  line-height: 1.4;
  margin: 0;
  z-index: 5;

  @media (max-width: 1000px) {
    font-size: 1.8rem;
  }

  @media (max-width: 850px) {
    bottom: 1.5rem;
    left: 2.5rem;
  }
`

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    opacity: 0.55;
    pointer-events: none;
    z-index: 2;
  }
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  pointer-events: none;
  z-index: 2;
`

const Image = styled(Img)`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: 1s transform ease;
  z-index: 1;

  &:hover {
    transform: scale(1.1);
  }
`
