import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import List from '../components/portfolio/list'
import Seo from '../components/seo'

const Portfolio = ({ data, pageContext }) => (
  <Layout>
    <Seo pageTitle={pageContext.title} {...data.wordPress.pageBy.seo} />
    <List
      categories={data.wordPress.categories.edges}
      projects={data.wordPress.projects.edges}
      commercialOffices={data.wordPress.pageBy.projectTemplateFields.commercialOffices}
      communitySpaces={data.wordPress.pageBy.projectTemplateFields.communitySpaces}
      historicRenovations={data.wordPress.pageBy.projectTemplateFields.historicRenovations}
      multifamilyResidential={data.wordPress.pageBy.projectTemplateFields.multifamilyResidential}
      restaurantsAndRental={data.wordPress.pageBy.projectTemplateFields.restaurantsAndRental}
    />
  </Layout>
)
export default Portfolio

export const query = graphql`
  query($wordpressId: Int!) {
    wordPress {
      ...PortfolioCategoryFragment
      ...PortfolioAllProjectsFragment
      pageBy(pageId: $wordpressId) {
        ...SeoFragment
        ...PortfolioFragment
      }
    }
  }
`
